.tealoadingImg {
  width: 100%;
  height: 30rem;
}

@media (max-width: 767px) {
  .tealoadingImg {
    width: 100%;
    height: 100%;
  }
}
