#navbarSupportedContent ul {
  width: 600px;
  justify-content: space-between;
}

.login-btn {
  margin-top: 0px;
}

.sub-info {
  font-size: 12px;
  color: #4e5651;
  font-weight: bold;
  margin-top: -1rem;
}

.sub-sub-info {
  font-size: 9px;
  color: #476251;
  font-weight: bold;
  font-style: italic;
}

.sub-sub-info-mrgin {
  margin-left: 113px;
}

.logonname {
  display: flex;
}

.h-name {
  margin-left: -68px
}

@media (max-width: 767px) {
  #navbarSupportedContent ul {
    width: 175px;
    justify-content: space-between;
    margin: auto;
  }

  .login-btn {
    margin-top: 15px;
  }

  .sub-sub-info-mrgin {
    margin-left: 45px;
  }

  .h-name {
    margin-left: 20px
  }
}