.btn-primary {
    background-color: #0c63ce  !important;
    color: white;
}

.success {
    width: 70%;
    color: green;
    font-size: larger;
    font: bold;
}

.errorMessage {
    color: red;
    text-align: left;
}

::-moz-selection {
    background: #fb2056;
}

::-webkit-selection {
    background: #fb2056;
}

::selection {
    background: #fb2056;
}

.gmaps {
    border: 1px solid grey;
    box-shadow: 8px 8px 0px 0px rgba(152, 147, 147, 0.75);
    width: 100%;
}



  