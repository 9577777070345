.image img {
  max-width: 100%;
  max-height: 100%;
  -webkit-transition: 0.2s all;
  filter: brightness(0.4);
}

.image img:hover {
  filter: brightness(0.5);
}

.flip-card {
  margin-top: 5%;
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: rgb(254, 254, 254);
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

#serviceFlashCards {
  padding-left: 10%;
}

.card-container {
  display: flex;
  /* Display cards in a row */
  justify-content: space-between;
  /* Add space between cards */
  padding: 30px;
  /* Add padding as needed */
}

/* Styles for the card container */
.card {
  max-width: 1112px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add a small shadow */
}

/* Styles for the cloudCard container */
.cloudCard {
  display: flex;
  align-items: center;
}

/* Styles for the card content */
.card-content {
  flex: 1;
  /* Allow content to grow and occupy remaining space */
  padding: 20px;
  /* Add padding to the content */
}

/* Styles for the card title */
.card-content h2 {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
}

/* Styles for the card content */
.card-content p {
  font-size: 16px;
  text-align: left;
}

/* Styles for the card image */
.card-image {
  flex: 1;
  /* Allow image to grow and occupy remaining space */
  overflow: hidden;
  /* Hide overflow if the image is larger than its container */
  border-radius: 10px;
  /* Rounded corners for the image container */
}

/* Styles for the image */
.card-image img {
  width: 100%;
  /* Make the image fill the entire image container */
  height: auto;
  /* Maintain the image's aspect ratio */
}

/* Media queries */
@media screen and (max-width: 700px) {
  #serviceFlashCards {
    padding-left: 0%;
  }

  .flip-card {
    width: 100%;
  }
}

.ImageFlip {
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(0.9);
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center; /* Center-align cards on mobile */
  }
}
